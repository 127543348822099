<script lang="ts" setup>
import { computed, defineProps, useAttrs } from 'vue'

defineOptions({ inheritAttrs: false })

const props = defineProps({
  sizeClasses: {
    type: String,
    default: 'h-6 w-6',
  },
})

const bgColorClass = computed(() => {
  const attrs = useAttrs()
  // If attrs.class contains nothing then we return the default
  if (!attrs.class)
    return 'voix-admin-bg-dark'

  return attrs.class
})
</script>

<template>
  <div class="flex flex-col justify-center items-center">
    <div
      class="relative flex justify-center items-center"
      :class="props.sizeClasses"
    >
      <div class="spinner absolute" :class="[bgColorClass, sizeClasses]" />
    </div>
    <slot />
  </div>
</template>

<style scoped>
  .spinner {
    margin: 100px auto;
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
  }

  @-webkit-keyframes sk-rotateplane {
    0% {
      -webkit-transform: perspective(120px);
    }
    50% {
      -webkit-transform: perspective(120px) rotateY(180deg);
    }
    100% {
      -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
    }
  }

  @keyframes sk-rotateplane {
    0% {
      transform: perspective(120px) rotateX(0deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
      transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
      transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
      -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
  }
</style>
